const CompareTab4 = ({activeTab, handleCompareDownloadWrap}) => {
    return (
        activeTab === 4 &&
        <div id="Tab4" className="tabcontent">
            <div id="downloadCompareTab">
                <div id="csvPreviewModal_compare">
                    <div>
                        <div>
                            <div>
                                <button type="button" className="btn btn-secondary" id="downloadBtn_compare" onClick={handleCompareDownloadWrap}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompareTab4;