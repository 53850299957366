import apiService from "../Services/ApiService";
import Utils from "./Utils";

class SplitPDFWrap {
    SplitPdfByContent(files, onComplete, onError) {               
        var count = files.length;
        console.log(count)
        var startFile = 0;
        this.SplitPdfByContentWrap(files, startFile, count, onComplete, onError)
    }

    async SplitPdfByContentWrap(files, currentVal, count, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/split_pdf_by_content";        
        console.log(url) 
        const file = files[currentVal];
        let getUniqueTimeStampStr = file.uploadTime;
        console.log(file)
        let that = this;                
        const queryParams = {
            filename: file.name
            , uniqueTimestampStr: getUniqueTimeStampStr
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetData(url, file.name, getUniqueTimeStampStr, files, currentVal, count, onComplete, onError, 
            function (files, currentVal, count, onComplete, onError, data) {
                currentVal++;
                if (currentVal < count) {
                    that.SplitPdfByContentWrap(files, currentVal, count, onComplete, onError)
                } else {
                    if (onComplete) onComplete(data); //,onError
                }
        });      
    }  
}
const SplitPDF = new SplitPDFWrap();
export default SplitPDF;