import DragDropBrowse from "./DragDropBrowse";

const FileSubmitPdf = ({files,onSetFiles}) => {   
    return (
        <div className="box-container">
            <DragDropBrowse files={files} onSetFiles={onSetFiles} />
            <button id="report-gen-submit" className="file-submit-btn">Submit</button>
            <button id="report-gen-trigger" className="file-submit-btn process-pdf-submit"
                data-action="/process_image">Submit</button>
            <button id="split_pdf_by_content" className="process-pdf-submit" data-action="/split_pdf_by_content"
            >Submit</button>
            <button id="split2" className="process-pdf-submit" data-action="/pdf_split_2" >Submit</button>
            <button id="split3" className="process-pdf-submit" data-action="/pdf_split_3" >Submit</button>
        </div>
    )
}

export default FileSubmitPdf;