import  { AuthProvider }  from '@elilillyco/spa_auth';
import Utils from "../Common/Utils";
const env = Utils.getEnvVars();

const config = {
   clientId: env.clientId,
   scopes: env.scopes, 
   openidPostLogoutRedirectUri: "/",
   cacheLocation: "localStorage",
   logLevel: "ERROR"   
}
export const authProvider = new AuthProvider(config);