import { useCallback, useEffect, useState } from "react";
import FileSubmitPdf from "./FileSubmitPdf";
import NotFound from "./NotFound";
import LoadingScreen from "./LoadingScreen";
import ToastMessage from "./ToastMessage";
import CompareTabPopup from "./CompareTabPopup";
import Utils from "../Common/Utils";
import ChunkUpload from "../Common/ChunkUpload";
import SplitPDF from "../Common/SplitPDF";
import ProcessPDF from "../Common/ProcessPDF";
import "../Styles/BodyContainer.css"
import FileSubmitEDB from "./FileSubmitEDB";
import ProcessEDB from "../Common/ProcessEDB";
import ShowProcessLogs from "./ShowProcessLogs";
import AuthProviderService from "../Services/AuthProviderService";
import iNext_User_Guide from '../img/iNext_User_Guide.pdf';

const Content = ({ initLoad, notFound, files, handleIncrement, handleEDBIncrement, cookieFile, setCookieFile, drapeSheer, setDrapeSheer, showToast, toastMsg, toastType, toastDur, hideDom, setHideDom, showComparePopup, setShowComparePopup, showCompareFile, setShowCompareFile, handleCompareDownload, validateCheckBox, setValidateCheckBox, reportIds, setReportIds, setEDBFiles, edbFiles,initloading,validPersona,setValidPersona, setpersonaAccess, personaAccess }) => {
  const [processLogs,setProcessLogs] = useState([])
  const [processTimer,setProcessTimer] = useState()
  const [processCompletedReportsTimer,setProcessCompletedReportsTimer] = useState()
  const [processCompletedReportsNTimer,setProcessCompletedReportsNTimer] = useState()
  const [processCompletedReportsMNTimer,setProcessCompletedReportsMNTimer] = useState()
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect(() => {
    handleLogsWrap(1000);  
    handleCompletedReportsWrap(1000);  
    handleCompletedReportsNightlyWrap(1000);  
    handleCompletedReportsManualWrap(1000);
    let hostname = document.location.hostname;
    if(hostname.match('dev')) {
      document.title = `iNext (dev) - Concur Report Auditing`;
    } else if(hostname.match('qa')) {
      document.title = `iNext (qa) - Concur Report Auditing`;
    } else {
      document.title = `iNext - Concur Report Auditing`;
    }
  },[])

  const handleLogsWrap = useCallback((sec) => {
    console.log (processTimer)   
    if(processTimer) {
      console.log('cleartimer')
      clearTimeout(processTimer)
    }
    setProcessTimer(() => {
      return setTimeout(()=>{
      ProcessEDB.fetchCronLogs((res)=>{
        console.log("fetch cron logs")
        console.log(res);
        if(res.data) {
          res.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setProcessLogs(res.data)
        }
        handleLogsWrap(10000)
      },(error)=>{
        console.log("fetch cron logs error")
        console.log(error);
        handleLogsWrap(10000)
      })
    },sec)})    
  },[])

  function parseToTimestamp(dateStr) {
    let timestamp;

    // Format: "01-09-2024 05:01:26:892012"
    if (dateStr.match(/^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}:\d{6}$/)) {
        const [datePart, timePart] = dateStr.split(' ');
        const [day, month, year] = datePart.split('-').map(Number);
        const [hour, minute, second, microsecond] = timePart.split(/[:.]/).map(Number);
        timestamp = new Date(year, month - 1, day, hour, minute, second).getTime() + Math.floor(microsecond / 1000);
    }

    // Format: "2024-08-11-23-30-03-282"
    else if (dateStr.match(/^\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}-\d{3}$/)) {
        const [year, month, day, hour, minute, second, millisecond] = dateStr.split('-').map(Number);
        timestamp = new Date(year, month - 1, day, hour, minute, second, millisecond).getTime();
    }

    // Return timestamp if parsed, otherwise return null
    return timestamp || null;
  }

  const handleCompletedReportsWrap = useCallback((sec) => {
    console.log (processCompletedReportsTimer)   
    if(processCompletedReportsTimer) {
      console.log('cleartimer')
      clearTimeout(processCompletedReportsTimer)
    }
    setProcessCompletedReportsTimer(() => {
      return setTimeout(()=>{
      ProcessEDB.fetchCompletedReportIds((res)=>{
        console.log("fetchCompletedReportIds")
        console.log(res);
        console.log("cookie file ",cookieFile)
        let cookieValue = Utils.checkAndGetSession();
        if(res.data) {
          res.data.filter((fileContent) => {
            let getUniqueTimeStampStr = fileContent.uploadTime;
            let getUniqueTimeStampStr1 = fileContent.uploadTime_new || "0";
            const isPresentName = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(fileContent.name));
            const isPresentTime = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(getUniqueTimeStampStr));
            let ffcontent = { name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2' }
            if(fileContent.process_type) {
              ffcontent = { name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2',process_type: fileContent.process_type  }
            }
            (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push(ffcontent)
            // (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push({ name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2' })           
            return fileContent
          });
        }
        cookieValue['dropFileName'].sort((a, b) => {
          const dateA = a.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(a.uniqueTimestampStr_new);
          const dateB = b.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(b.uniqueTimestampStr_new);
          console.log(dateA,dateB)
          // Handle the '0' cases separately
          // if (dateA === 0 && dateB !== 0) return -1; // '0' should come first
          // if (dateA !== 0 && dateB === 0) return 1;  // '0' should come first
      
          // Sort by date-time values (or both '0')
          return dateB - dateA;
        });
      
        // Utils.setCookie("filesubmit", JSON.stringify(cookieValue), 1)
        Utils.setSession("filesubmit", JSON.stringify(cookieValue))

        console.log('final cookie value ',cookieValue)
        setCookieFile(cookieValue['dropFileName'])
        handleCompletedReportsWrap(10000)
      },(error)=>{
        console.log("fetchCompletedReportIds error")
        console.log(error);
        handleCompletedReportsWrap(10000)
      })
    },sec)})    
  },[])

  

  const handleCompletedReportsNightlyWrap = useCallback((sec) => {
    console.log (processCompletedReportsNTimer)   
    if(processCompletedReportsNTimer) {
      console.log('cleartimer')
      clearTimeout(processCompletedReportsNTimer)
    }
    setProcessCompletedReportsNTimer(() => {
      return setTimeout(()=>{
      ProcessEDB.fetchCompletedReportIdsNightly((res)=>{
        console.log("fetchCompletedReportIdsNightly")
        console.log(res);
        console.log("cookie file ",cookieFile)
        let cookieValue = Utils.checkAndGetSession();
        if(res.data) {
          res.data.filter((fileContent) => {
            let getUniqueTimeStampStr = fileContent.uploadTime;
            let getUniqueTimeStampStr1 = fileContent.uploadTime_new || "0";
            const isPresentName = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(fileContent.name));
            const isPresentTime = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(getUniqueTimeStampStr));
            let ffcontent = { name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2' }
            if(fileContent.process_type) {
              ffcontent = { name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2',process_type: fileContent.process_type  }
            }
            (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push(ffcontent)
            // (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push({ name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr1, phases:'2' })           
            return fileContent
          });
        }
        cookieValue['dropFileName'].sort((a, b) => {
          const dateA = a.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(a.uniqueTimestampStr_new);
          const dateB = b.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(b.uniqueTimestampStr_new);
          console.log(dateA,dateB)
          // Handle the '0' cases separately
          // if (dateA === 0 && dateB !== 0) return -1; // '0' should come first
          // if (dateA !== 0 && dateB === 0) return 1;  // '0' should come first
      
          // Sort by date-time values (or both '0')
          return dateB - dateA;
        });
        // Utils.setCookie("filesubmit", JSON.stringify(cookieValue), 1)
        Utils.setSession("filesubmit", JSON.stringify(cookieValue))

        console.log('final cookie value ',cookieValue)
        setCookieFile(cookieValue['dropFileName'])
        handleCompletedReportsNightlyWrap(10000)
      },(error)=>{
        console.log("fetchCompletedReportIds error")
        console.log(error);
        handleCompletedReportsNightlyWrap(10000)
      })
    },sec)})    
  },[])

  const handleCompletedReportsManualWrap = useCallback((sec) => {
    console.log (processCompletedReportsMNTimer)   
    if(processCompletedReportsMNTimer) {
      console.log('cleartimer')
      clearTimeout(processCompletedReportsMNTimer)
    }
    setProcessCompletedReportsMNTimer(() => {
      return setTimeout(()=>{
      ChunkUpload.fetchCompletedReportIdsManual((res)=>{
        console.log("fetchCompletedReportIdsManual")
        console.log(res);
        console.log("cookie file ",cookieFile)
        let cookieValue = Utils.checkAndGetSession();
        if(res.data) {
          res.data.filter((fileContent) => {
            let getUniqueTimeStampStr = fileContent.uniqueTimestampStr;
            const isPresentName = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(fileContent.name));
            const isPresentTime = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(getUniqueTimeStampStr));
            (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push({ name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr, phases:'1' })           
            return fileContent
          });
        }
        cookieValue['dropFileName'].sort((a, b) => {
          const dateA = a.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(a.uniqueTimestampStr_new);
          const dateB = b.uniqueTimestampStr_new === '0' ? 0 : parseToTimestamp(b.uniqueTimestampStr_new);
          console.log(dateA,dateB)
          // Handle the '0' cases separately
          // if (dateA === 0 && dateB !== 0) return -1; // '0' should come first
          // if (dateA !== 0 && dateB === 0) return 1;  // '0' should come first
      
          // Sort by date-time values (or both '0')
          return dateB - dateA;
        });
        // Utils.setCookie("filesubmit", JSON.stringify(cookieValue), 1)
        Utils.setSession("filesubmit", JSON.stringify(cookieValue))

        console.log('final cookie value ',cookieValue)
        setCookieFile(cookieValue['dropFileName'])
        handleCompletedReportsManualWrap(10000)
      },(error)=>{
        console.log("fetchCompletedReportIds error")
        console.log(error);
        handleCompletedReportsManualWrap(10000)
      })
    },sec)})    
  },[])

  const handlePDFSubmit = async (event) => {
    setDrapeSheer(true)
    if (files.length < 1) {
      setDrapeSheer(false)
      showToast("Please upload any report validation!!!", "danger", 3000);
      return false;
    }
    let wrongExt = false;
    // let cookieValue = Utils.checkAndGetSession();  
    // console.log(cookieValue)
    console.log(files)
    let filenames = [];
    const authClaim = await AuthProviderService.getClaims();
    files.filter((fileContent) => {
      let getUniqueTimeStampStr = fileContent.uploadTime;
      let dfCount = fileContent.name && fileContent.name.match(new RegExp("(.pdf|.csv|.xlsx|.xls)$", "gi"));
      // const isPresentName = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(fileContent.name));
      // const isPresentTime = cookieValue['dropFileName'].some(obj => Object.values(obj).includes(getUniqueTimeStampStr));
      // (!isPresentName || !isPresentTime) && cookieValue['dropFileName'].push({ name: fileContent.name, uniqueTimestampStr: getUniqueTimeStampStr, phases:'1' })
      if (!dfCount || (dfCount && !dfCount.length)) {
        wrongExt = true;
      }
      let fileContentObj = {reportid:fileContent.name,uniqueTimestampStr:getUniqueTimeStampStr, uniqueTimestampStr_new: getUniqueTimeStampStr,'email': authClaim.username}
      filenames.push(fileContentObj)
      return fileContent
    });
    if (wrongExt) {
      setDrapeSheer(false)
      showToast("Please upload .pdf|.csv|.xlsx|.xls format report!!!", "danger", 3000);
      return false;
    }
    // console.log(cookieValue)
    // Utils.setCookie("filesubmit", JSON.stringify(cookieValue), 1)
    // Utils.setSession("filesubmit", JSON.stringify(cookieValue))

    // console.log(cookieValue)
    // setCookieFile(cookieValue['dropFileName'])

    ChunkUpload.prepareReportManual(filenames, function (data) {
      console.log("ajax complete ", data)            
    }, function (error) {
      console.log("error report manual ", error)            
    }); 

    ChunkUpload.uploadChunkFile(files, function (data) {
      console.log("ajax complete ", data)
      showToast("Successfully pdf uploaded!!!", "success", 3000);
      SplitPDF.SplitPdfByContent(files, function (data) {
        console.log("ajax complete ", data)
        showToast("Successfully pdf split!!!" , "success", 3000);
        ProcessPDF.ReadTextFromPDF(files, "report", async function (data) {
          console.log("ajax complete ", data)
          showToast("Successfully pdf to csv report converted!!!", "success", 3000);
          await delay(5000);
          ProcessPDF.ReadTextFromPDF(files, "bill", async function (data) {
            console.log("ajax complete ", data)
            await delay(5000);
            ChunkUpload.prepareReportManualComplete(filenames, function (data) {
              console.log("ajax complete ", data)   
            }, function (error) {
              console.log("error report manual ", error)  
            });
            setDrapeSheer(false)
            showToast("Successfully pdf to csv bill converted!!!", "success", 3000);
            setShowCompareFile({filename:files[files.length-1].name,uniqueTimestampStr:files[files.length-1].uploadTime, phases:'1'})  
            setShowComparePopup(true);                         
          }, function (error) {
            setDrapeSheer(false)
            showToast("Error pdf to csv bill converted!!!", "danger", 5000);
          })          
        }, function (error) {
          setDrapeSheer(false)
          showToast("Error pdf to csv report converted!!!", "danger", 5000);
        })
      }, function (error) {
        setDrapeSheer(false)
        showToast("Error pdf split!!!", "danger", 5000);
      })
    }, function (error) {
      setDrapeSheer(false)
      showToast("Error pdf uploaded Failed... Please try again !!!", "danger", 5000);
    })
  }

  const runNextProcessAfterEDBTrigger = (data) => {
    if(data.report_id.length < 1 && data.error_report_ids.length > 0) {
      setDrapeSheer(false)
      let printReportIds = [];
      data.error_report_ids.filter(item => { 
        printReportIds.push(item.reportid)
        return item; 
      })
      showToast("Error on Report id's "+printReportIds.join(', ')+"!!!", "danger", 5000);
      return false
    }
    if(data.report_id.length > 0 && data.error_report_ids.length > 0) {
      let printReportIds = [];
      data.error_report_ids.filter(item => { 
        printReportIds.push(item.reportid)
        return item; 
      })
      showToast("Successfully fetched EDB data but Error on Report id's "+printReportIds.join(', ')+"!!!", "success", 5000);
    } else {
      showToast("Successfully fetched EDB data!!!", "success", 5000);
    }
    // setDrapeSheer(false)
    // data.report_id = JSON.parse(data.report_id)
    ProcessEDB.transfer_bills(data,function (data) {
      console.log("ajax complete ", data) 
      // setDrapeSheer(false)         
      showToast("Successfully transfered bill data from edb!!!", "success", 5000);  
      // TBD call split 
      // TBD call ocr
      // TBD call compare
      data.report_id = JSON.parse(data.report_id)
      data.report_id = data.report_id.map(item => { item.name=item.reportid+".pdf"; return item; })
      console.log(data)                      
      SplitPDF.SplitPdfByContent(data.report_id, function (res) {
        console.log("ajax complete ", res)
        // setDrapeSheer(false)     
        showToast("Successfully pdf split!!!" , "success", 3000);              
        ProcessPDF.ReadTextFromPDF(data.report_id, "bill", function (res) {
          console.log("ajax complete ", res)
          // setDrapeSheer(false)
          showToast("Successfully pdf to csv bill converted!!!", "success", 3000);
          ProcessEDB.fetchCompareData(data.report_id, function (res) {
            console.log("ajax complete ", res)
            setDrapeSheer(false)
            showToast("Successfully Completed compare!!!", "success", 3000);
            ProcessEDB.handleCompareDownload(data.report_id, function (res) {
              console.log("ajax complete ", res);
            }, 
            function (error) {
              
            })
          }, function (error) {
            setDrapeSheer(false)
            showToast("Error on compare!!!", "danger", 5000);
          })
        }, function (error) {
          setDrapeSheer(false)
          showToast("Error pdf to csv bill converted!!!", "danger", 5000);
        })                        
      }, function (error) {
        setDrapeSheer(false)
        showToast("Error pdf split!!!", "danger", 5000);
      })
    }, function (error) {
      setDrapeSheer(false)
      showToast("Error transferring bill data from edb!!!", "danger", 5000);
    });
  }

  const checkEDBData = (data) => {
    if(data.status === 'running') {
      data.report_id = JSON.parse(data.report_id)
      ProcessEDB.checkEDB(data,function (data) {
        console.log("ajax complete ", data)
        if(data.status === 'running') {
          checkEDBData(data);
        } else if(data.status === 'error') {
          setDrapeSheer(false)
          showToast("Error fetched EDB data!!!", "danger", 5000);
        } else {
          runNextProcessAfterEDBTrigger(data)
          // // setDrapeSheer(false)
          // showToast("Successfully fetched EDB data!!!", "success", 5000);
          // data.report_id = JSON.parse(data.report_id)
          // ProcessEDB.transfer_bills(data,function (data) {
          //   console.log("ajax complete ", data) 
          //   // setDrapeSheer(false)         
          //   showToast("Successfully transfered bill data from edb!!!", "success", 5000);  
          //   // TBD call split 
          //   // TBD call ocr
          //   // TBD call compare
          //   data.report_id = JSON.parse(data.report_id)
          //   data.report_id = data.report_id.map(item => { item.name=item.reportid+".pdf"; return item; })
          //   console.log(data)                      
          //   SplitPDF.SplitPdfByContent(data.report_id, function (res) {
          //     console.log("ajax complete ", res)
          //     // setDrapeSheer(false)     
          //     showToast("Successfully pdf split!!!" , "success", 3000);              
          //     ProcessPDF.ReadTextFromPDF(data.report_id, "bill", function (res) {
          //       console.log("ajax complete ", res)
          //       setDrapeSheer(false)
          //       showToast("Successfully pdf to csv bill converted!!!", "success", 3000);
          //       // setShowCompareFile({filename:files[files.length-1].name,uniqueTimestampStr:files[files.length-1].uploadTime})  
          //       // setShowComparePopup(true);
                
          //     }, function (error) {
          //       setDrapeSheer(false)
          //       showToast("Error pdf to csv bill converted!!!", "danger", 5000);
          //     })                        
          //   }, function (error) {
          //     setDrapeSheer(false)
          //     showToast("Error pdf split!!!", "danger", 5000);
          //   })
          // }, function (error) {
          //   setDrapeSheer(false)
          //   showToast("Error transferring bill data from edb!!!", "danger", 5000);
          // });
        }
      }, function (error) {
        setDrapeSheer(false)
        showToast("Error fetched EDB data!!!", "danger", 5000);
      });
    } else if(data.status === 'error') {
      setDrapeSheer(false)
      showToast("Error fetched EDB data!!!", "danger", 5000);
    } else {
      // setDrapeSheer(false)
      // showToast("Successfully fetched EDB data!!!", "success", 5000);
      //TBD transfer_bills
      runNextProcessAfterEDBTrigger(data)
    }    
  }

  const handleSubmitEDB = (event) => {
    setDrapeSheer(true)
    if (reportIds.length < 1) {
      setDrapeSheer(false)
      showToast("Please enter any report id's for validation!!!", "danger", 3000);
      return false;
    }
    console.log("report ID's ",reportIds)
    // if(reportIds.length > 20) {
    //   setDrapeSheer(false)
    //   showToast("Please upload less than 20 report id's for processing!!!", "danger", 3000);
    //   return false;
    // }
    // ProcessEDB.triggerEDB(reportIds,function (data) {
    //   console.log("ajax complete ", data)
    //   checkEDBData(data);
    // }, function (error) {
    //   setDrapeSheer(false)
    //   showToast("Error on Report id's!!!", "danger", 5000);
    // });

    ProcessEDB.prepareReportQueue(reportIds, function (data) {
      console.log("ajax complete ", data)
      // if(data.status === 'error') {
      //   setDrapeSheer(false)
      //   showToast("upload report ids are greater than 20 report id's for processing!!!", "danger", 3000);
      //   return false;
      // }
      setDrapeSheer(false)
      showToast("Report ids has been scheduled please come back later to download excel reports!!!", "success", 3000);
    }, function (error) {
      setDrapeSheer(false)
      showToast("Error on report id's!!!", "danger", 5000);
    });    
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("handle submit")
    if(validateCheckBox==="singleReport") {
      handlePDFSubmit(event)
    } else if(validateCheckBox==="validateReportId") {
      handleSubmitEDB(event)
    }
  };

  const onRadioChange = (event,id) => {
    console.log("onRadio change",event,id)  
    // if(id === 'validateReportId')  return
    setValidateCheckBox(id)
  }

  useEffect(() => {
    var cookieValue = Utils.checkAndGetCookie();
    console.log(cookieValue)
  }, [])

  return (
    <div className="body-container">
      {initLoad && initloading ? <></> : notFound ? (<div className="textContainer"><NotFound /></div>) :
        <>
          {drapeSheer && <LoadingScreen />}
          {toastMsg && toastType && <ToastMessage message={toastMsg} toastType={toastType} duration={toastDur} setHideDom={setHideDom} hideDom={hideDom} />}
          <div className="textContainer">
            <h1 className="title">
              iNext - Concur Report Auditing 
              <a href={iNext_User_Guide} target="_blank" rel="noopener noreferrer"><svg className="info-msg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="8"></line>
              </svg></a>
            </h1>               
            <form id="reportForm" className="box" method="POST"
              enctype="multipart/form-data" onSubmit={handleSubmit}>
              <div className="box-container main-page-checkbox-hide">
                {/* <div className="fieldgroup">
                  <input type="radio" value="singleReport" id="singleReport" checked={validateCheckBox==="singleReport" && true} data-multiple="" onChange={(event) => onRadioChange(event,"singleReport") }/>                 
                  <label for="singleReport">Validate Single Report</label>
                </div> */}
                <div className="fieldgroup">
                  <input type="radio" value="validateReportId" id="validateReportId" checked={validateCheckBox==="validateReportId" && true} data-multiple="" onChange={(event) => onRadioChange(event,"validateReportId") }/>               
                  <label for="validateReportId">Validate Report Id's</label>
                </div>
              </div>
              {(validateCheckBox==="singleReport") && <FileSubmitPdf files={files} onSetFiles={handleIncrement} />}
              {(validateCheckBox==="validateReportId") && <FileSubmitEDB  setEDBFiles={handleEDBIncrement} edbFiles={edbFiles} reportIds={reportIds} setReportIds={setReportIds}/>}
              
            </form>
          </div>

          {showComparePopup && <CompareTabPopup cookieFile={cookieFile} setShowComparePopup={setShowComparePopup} showCompareFile={showCompareFile} handleCompareDownload={handleCompareDownload} setDrapeSheer={setDrapeSheer} showToast={showToast} />}

          <>
            {<ShowProcessLogs processLogs={processLogs} /> }
          </>
        </>
      }
    </div>
  )
}

export default Content;