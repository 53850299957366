import { useCallback, useEffect, useState } from "react";
import "../Styles/CompareTabPopup.css"
import Utils from "../Common/Utils";
import apiService from "../Services/ApiService";
import CompareTab1 from "./CompareTab1";
import CompareTab2 from "./CompareTab2";
import CompareTab4 from "./CompareTab4";

const CompareTabPopup = ({ cookieFile, setShowComparePopup, showCompareFile, handleCompareDownload, setDrapeSheer, showToast }) => {
    const [activeTab, setActiveTab] = useState(3);
    const [previewError, setPreviewError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [csvHeader, setCsvHeader] = useState([]);
    const [csvBody, setCsvBody] = useState([]);
    const [rbvendorreport, setRBVendorreport] = useState([]);
    const [rbvendorbill, setRBVendorbill] = useState([]);
    const [vbamatch, setvbamatch] = useState([])
    const [skipMatch, setSkipMatch] = useState(false)
    const [selectreportvendor, setselectreportvendor] = useState("")
    const [selectbillvendor, setselectbillvendor] = useState("")
    const [retryRBVendor, setRetryRBVendor] = useState(false)
    const [vbconfig, setvbconfig] = useState([])
    const [vbconfigdone, setvbconfigdone] = useState(false)
    const [toastTimer, setToastTimer] = useState(undefined);

    const clearAll = () => {
        setvbconfig([]);
        setRetryRBVendor(false);
        setselectbillvendor("")
        setselectreportvendor("")
        setvbamatch([])
        setRBVendorbill([])
        setRBVendorreport([])
        setvbconfigdone(false)
    }

    const writeReportBillsVendor = () => {
        console.log("inside writeReportBillsVendor")
        // setShowLoading(false)        
    }

    const putRBVendor = useCallback((data, search, whichReportOrBill) => {
        let reportRes = data?.responseText
        let csvData = JSON.parse(reportRes)
        let csvHeader = csvData[0];
        csvData.shift()
        let csvBody = csvData;
        console.log(csvHeader, csvBody)
        var searchString = search;
        var index = Utils.findIndex(csvHeader, searchString);
        // Check if the string exists in the array
        if (index !== -1) {
            console.log("The index of '" + searchString + "' in the array is: " + index);
            let rbv = {}
            for (var i in csvBody) {
                if (csvBody[i][index]) rbv[csvBody[i][index].trim()] = ""
            }
            console.log("unique vendors", Object.keys(rbv), rbv)
            whichReportOrBill === "report" ? setRBVendorreport(Object.keys(rbv)) : setRBVendorbill(Object.keys(rbv))
        } else {
            console.log("'" + searchString + "' is not found in the array.");
        }
    }, [setRBVendorreport, setRBVendorbill])

    const fetchCompareData = async (action, qs, onComplete, onError) => {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + action;
        console.log(url)
        let fName = showCompareFile.filename
        let uniqueTimestampStr = showCompareFile.uniqueTimestampStr;
        let queryParams = {
            filename: fName
            , uniqueTimestampStr: uniqueTimestampStr
        };
        queryParams = { ...queryParams, ...qs }
        console.log("query params", queryParams)
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString && action !== "/get_vendor_cfg") url = `${url}?${queryString}`
        await apiService.fetchGetData(url, fName, uniqueTimestampStr, [], 0, 1, function (data) {
            console.log("ajax complete ", data)
            if (onComplete) onComplete(data);
        }, function (error) {
            console.log("error")
            if (onError) onError();
        }, function (files, currentVal, count, onComplete, onError, data) {
            console.log("check next", files, currentVal, count, data)
            if (onComplete) onComplete(data);
        });
    }

    const previewTable = async (action) => {
        setPreviewError(false)
        setShowLoading(true)
        console.log(showCompareFile)
        let fName = showCompareFile['filename'];
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + action;
        console.log(url)
        let uniqueTimestampStr = showCompareFile['uniqueTimestampStr']
        const queryParams = {
            filename: fName
            , uniqueTimestampStr: uniqueTimestampStr
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetData(url, fName, uniqueTimestampStr, [], 0, 1, function (data) {
            console.log("ajax complete ", data)
            setShowLoading(false)
            let csvData = data?.responseText
            csvData = JSON.parse(csvData)
            let csvHeader = csvData[0];
            csvData.shift()
            setCsvHeader(csvHeader);
            setCsvBody(csvData);
            setShowDownload(true)
        }, function (error) {
            console.log("error")
            setShowLoading(false)
            setPreviewError(true)
        }, function (files, currentVal, count, onComplete, onError, data) {
            console.log("check next", files, currentVal, count, data)
            if (onComplete) onComplete(data);
        });
    };

    const previewReport = (event) => {
        console.log("preview Report")
        setActiveTab(1)
        previewTable("/preview_report")
    }

    const previewBills = (event) => {
        console.log("previewBills")
        setActiveTab(2)
        previewTable("/preview_ocr_report")
    }

    const matchVendor = (event) => {
        console.log("matchVendor")
        setActiveTab(3)
        setShowLoading(true)
        clearAll()
        fetchCompareData("/preview_report", {}, function (data_r) {
            console.log("preview report", data_r)
            putRBVendor(data_r, "Vendor ", "report")
            fetchCompareData("/preview_ocr_report", {}, function (data_b) {
                console.log("preview ocr", data_b)
                putRBVendor(data_b, "Vendor Name", "bill")
                fetchCompareData("/get_vendor_cfg", {}, function (data) {
                    console.log("finally", data)
                    let vendorCfg = data?.responseText
                    setvbconfig(vendorCfg)
                    setRetryRBVendor(true);
                }, function () {
                    console.log('error three')
                    setRetryRBVendor(true);
                })
            }, function () {
                console.log('error two')
            })
        }, function () {
            console.log('error one')
        })
    }

    const downloadExcel = (event) => {
        console.log("downloadExcel")
        setActiveTab(4)
    }

    const closeTabPopup = (event) => {
        console.log("close tab")
        setShowComparePopup(false)
    }

    const handleCompareDownloadWrap = async (event) => {
        await handleCompareDownload(showCompareFile.filename, showCompareFile.uniqueTimestampStr)
    }

    const downloadbill = async (event) => {
        console.log("inside download bill", showCompareFile, event.target.innerHTML)
        setDrapeSheer(true)
        let fName = event.target.innerHTML;
        let rfName = showCompareFile.filename;
        let uniqueTimestampStrN = showCompareFile.uniqueTimestampStr;
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/download_pdf";
        console.log(url)
        console.log(fName, rfName, uniqueTimestampStrN)
        const queryParams = {
            filename: rfName
            , billfName: fName
            , mode: 'downloadpdf'
            , uniqueTimestampStr: uniqueTimestampStrN
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        console.log(url);
        await apiService.fetchDownloadDataXL(url, fName, function (data) {
            console.log("ajax complete ", data)
            setDrapeSheer(false)
        }, function (error) {
            setDrapeSheer(false)
            showToast("Error download compare!!!", "danger", 5000);
        });
    }

    const downloadCsv = async (event, type, action) => {
        setDrapeSheer(true)
        console.log("inside download Csv", event, showCompareFile, type, action)
        var [basename] = Utils.splitFilename(showCompareFile.filename);
        let fName = basename + ".csv";
        let oName = basename + "_" + type + ".csv";
        let uniqueTimestampStrN = showCompareFile.uniqueTimestampStr;
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + action;
        console.log(url)
        const queryParams = {
            filename: fName
            , mode: `download${type}`
            , uniqueTimestampStr: uniqueTimestampStrN
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        console.log(url)
        await apiService.fetchDownloadData(url, oName, function (data) {
            console.log("ajax complete ", data)
            setDrapeSheer(false)
        }, function (error) {
            setDrapeSheer(false)
            showToast("Error download csv!!!", "danger", 5000);
        });
    }

    const downloadReportBillBtn = () => {
        setDrapeSheer(true)
        fetchCompareData("/get_vendor_cfg", {}, function (data) {
            console.log("finally", data)
            setDrapeSheer(false)
            let jsonString = JSON.stringify(data?.responseText)
            const blob = new Blob([jsonString], { type: "application/json" });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            // Specify the filename
            link.download = "vendorCfg.txt";
            // Trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, function () {
            console.log('error match')
            setDrapeSheer(false)
            showToast("Error download matched vendor csv!!!", "danger", 5000);
        })
    }

    const compareRBWrap = () => {
        let action = "/compare_report";
        fetchCompareData(action, { skipMatch: skipMatch }, function (data) {
            console.log("finally", data)
            setDrapeSheer(false)
            showToast("Comparing report with bills Successfully!!!", "success", 5000);
            handleCompareDownloadWrap()
        }, function () {
            console.log('error match')
            setDrapeSheer(false)
            showToast("Error in compare!!!", "danger", 5000);
        })
    }

    const postVendorCfg = async (data, savecfgonly) => {
        let vendorCfg = data?.responseText || []
        console.log("postvendor", vendorCfg, vbamatch)
        let finalVC = Utils.mergeArraysByKey(vendorCfg, vbamatch, 'alias');
        console.log(finalVC);
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/add_vendor_cfg";
        console.log(url)
        let ajaxData = JSON.stringify({ "cfg": finalVC });
        await apiService.fetchPostData(url, ajaxData, function (data) {
            console.log("ajax complete ", data)
            // $('.curtain-screen').css({ display: 'none' });
            // showToast("Successfully pdf uploaded!!!", "success", 5000);
            if (!savecfgonly) {
                compareRBWrap();
            } else { setDrapeSheer(false); showToast("Successfully vendor cfg uploaded!!!", "success", 5000); }
        }, function (error) {
            // $('.curtain-screen').css({ display: 'none' });
            setDrapeSheer(false)
            showToast("Error  vendor cfg uploaded Failed... Please try again !!!", "danger", 5000);
        })
    }

    const submitCompareRB = (savecfg) => {
        console.log("inside submit")
        console.log(skipMatch)
        setDrapeSheer(true)
        if (skipMatch && !savecfg) {
            compareRBWrap();
        } else {
            fetchCompareData("/get_vendor_cfg", {}, function (data) {
                console.log("finally got vendor cfg", data)
                postVendorCfg(data)
            }, function () {
                console.log('error get vendor cfg')
                postVendorCfg()
            })
        }
    }

    const rvendorclickhandler = (name) => {
        selectreportvendor === name ? setselectreportvendor("") : setselectreportvendor(name)
    }

    const bvendorclickhandler = (name) => {
        selectbillvendor === name ? setselectbillvendor("") : setselectbillvendor(name)
    }

    const rbvendormatchhandler = () => {
        console.log(selectreportvendor, selectbillvendor);
        console.log("vbamatch", vbamatch)
        if (selectreportvendor !== "" || selectbillvendor !== "") {

            // console.log(vendorMatchedName,billsMatchedName)
            let userInput = prompt("Please enter Vendor Alias:");
            if (userInput !== null && userInput.trim() !== "") {
                const userInputRegex = /[^a-zA-Z0-9]/g;
                // User clicked OK or entered a value
                console.log("User entered:", userInput);
                const keyToFind = userInput.toLowerCase().trim().replace(userInputRegex, "");
                console.log(keyToFind);
                // Check if the key exists in any of the objects using some()
                const newvbamatch = [...vbamatch]; // Create a copy
                console.log("new ", newvbamatch)
                const keyExists = newvbamatch.some(obj => obj['alias'] === keyToFind);
                const keyIndex = newvbamatch.findIndex(obj => obj['alias'] === keyToFind);
                if (keyExists) {
                    // console.log(vbamatch[keyIndex])                
                    selectreportvendor && newvbamatch[keyIndex]['report'].push(selectreportvendor);
                    selectbillvendor && newvbamatch[keyIndex]['bill'].push(selectbillvendor);
                    setvbamatch(newvbamatch);
                    setRBVendorreport(Utils.removeIntersection(rbvendorreport, newvbamatch[keyIndex]['report']));
                    setRBVendorbill(Utils.removeIntersection(rbvendorbill, newvbamatch[keyIndex]['bill']));
                } else {
                    let vbaObj = {}
                    vbaObj['alias'] = keyToFind
                    vbaObj['report'] = selectreportvendor ? [selectreportvendor] : []
                    vbaObj['bill'] = selectbillvendor ? [selectbillvendor] : []
                    // vbamatch.push(vbaObj)
                    newvbamatch.push(vbaObj)
                    setvbamatch(newvbamatch)
                    setRBVendorreport(Utils.removeIntersection(rbvendorreport, vbaObj['report']));
                    setRBVendorbill(Utils.removeIntersection(rbvendorbill, vbaObj['bill']));
                }
                console.log(vbamatch)

                setselectreportvendor("")
                setselectbillvendor("")
            } else {
                // User clicked Cancel
                console.log("User cancelled the prompt.");
            }
        } else {
            alert("nothing to match")
        }
    }

    const saveVendorCfg = () => {
        fetchCompareData("/get_vendor_cfg", {}, function (data) {
            console.log("finally got vendor cfg", data)
            postVendorCfg(data, true)
        }, function () {
            console.log('error get vendor cfg')
            postVendorCfg([], true)
        })
    }

    const saveMatchedFileHandler = () => {
        setDrapeSheer(true)
        saveVendorCfg()
    }

    const memoizedHideToast = useCallback(() => {
        setShowLoading(false)
        setToastTimer(undefined);
    }, []);

    useEffect(() => {
        if(showCompareFile.phases === '1') {
            matchVendor();
        } else if(showCompareFile.phases === '2') {
            previewReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(rbvendorreport, rbvendorbill, vbconfig)
        let rbvendortmp = { 'report': rbvendorreport, 'bill': rbvendorbill }
        vbconfig.filter((cfg) => {
            rbvendortmp['report'] = Utils.removeIntersection(rbvendortmp['report'], cfg['report']);
            rbvendortmp['bill'] = Utils.removeIntersection(rbvendortmp['bill'], cfg['bill']);
            return cfg;
        })
        setRBVendorreport(rbvendortmp['report']);
        setRBVendorbill(rbvendortmp['bill']);
        console.log(rbvendorreport, rbvendorbill, rbvendortmp)
        console.log("after")
        writeReportBillsVendor()
        setvbconfigdone(true)
        let duration = 3000;
        const timer = toastTimer;
        if (timer) {
            clearTimeout(timer);
            setToastTimer(undefined);
        }

        // Set the new toast timer
        setToastTimer(setTimeout(memoizedHideToast, duration));

        // Cleanup function to clear the timer on unmount
        return () => {
            if (toastTimer) {
                clearTimeout(toastTimer);
                setToastTimer(undefined);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retryRBVendor]);

    return (
        <div className="compareViewer">
            <div className="popup">
                <div className="popup-content">
                    <span className="close" onClick={closeTabPopup}>&times;</span>
                    <div className="tab">
                        <button className={activeTab === 1 ? "tablinks active" : "tablinks"} onClick={previewReport}>Preview Report</button>
                        <button className={activeTab === 2 ? "tablinks active" : "tablinks"} onClick={previewBills}>Preview Bills</button>
                        { showCompareFile.phases === '1' && <button className={activeTab === 3 ? "tablinks active" : "tablinks"} onClick={matchVendor}>Match vendor names</button>}
                        <button className={activeTab === 4 ? "tablinks active" : "tablinks"} onClick={downloadExcel}>Download Report</button>
                    </div>
                    <CompareTab1 activeTab={activeTab} previewError={previewError} showLoading={showLoading} csvHeader={csvHeader} csvBody={csvBody} showDownload={showDownload} downloadCsv={downloadCsv} />
                    <CompareTab2 activeTab={activeTab} previewError={previewError} showLoading={showLoading} csvHeader={csvHeader} csvBody={csvBody} showDownload={showDownload} downloadCsv={downloadCsv} downloadbill={downloadbill} />

                    {activeTab === 3 &&
                        <div id="Tab3" className="tabcontent">
                            <div id="compareROTab">
                                {(showLoading) ?
                                    <div className="loading-circle_inner"></div>
                                    :
                                    <div className="config-writer-class">
                                        <div className="flex-container">
                                            <div className="flex-item">
                                                <div className="rpt-vendor-cfg-lb-container">
                                                    <label className="rpt-vendor-cfg-lb">Report Vendor Name</label>
                                                </div>
                                                <div id="reportvendorcfg" className="rpt-vendor-cfg-container">
                                                    {vbconfigdone && rbvendorreport.map((rbitem, index) => {
                                                        return <div key={index} onClick={() => { rvendorclickhandler(rbitem) }} className={selectreportvendor === rbitem ? "active" : ""}>{rbitem}</div>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="flex-item">
                                                <div className="bill-vendor-cfg-lb-container">
                                                    <label className="bill-vendor-cfg-lb">Bills Vendor Name</label>
                                                </div>
                                                <div id="billsvendorcfg" className="bill-vendor-cfg-container">
                                                    {vbconfigdone && rbvendorbill.map((rbitem, index) => {
                                                        return <div key={index} onClick={() => { bvendorclickhandler(rbitem) }} className={selectbillvendor === rbitem ? "active" : ""}>{rbitem}</div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-container compare-match-outer">
                                            <button id="matchReportBillBtn" className="match-vendor-btn" onClick={() => rbvendormatchhandler()}>Match</button>
                                            <button id="downloadReportBillBtn" className="download-match-vendor-btn" onClick={downloadReportBillBtn}>Download Matched File</button>

                                        </div>
                                        <div id="vbaMatchContainer" className="flex-container vbmatch-outer-container">
                                            {vbamatch.length ? <table>
                                                <tr>
                                                    <th>Alias</th>
                                                    <th>Report Vendor</th>
                                                    <th>Bills Vendor</th>
                                                </tr>
                                                {vbamatch.map((vbkey, index) => {
                                                    return (<tr key={index}>
                                                        <td>{vbkey['alias']}</td>
                                                        <td>{vbkey['report'].join(", ")}</td>
                                                        <td>{vbkey['bill'].join(", ")}</td>
                                                    </tr>)
                                                })
                                                }
                                            </table> : <></>}
                                        </div>
                                        {vbamatch.length ? <div className="flex-container compare-match-outer">
                                            <button id="saveReportBillBtn" className="save-match-btn" onClick={() => saveMatchedFileHandler()}>Save Matched File</button>
                                        </div> : <></>}
                                        <div className="flex-container">
                                            <div className="flex-item skipmatching-container">
                                                <input type="checkbox" id="myManualCheckOrNotCb" className="skip-match-in-check" checked={skipMatch}
                                                    onChange={(event) => setSkipMatch(event.target.checked)} />
                                                <label for="myManualCheckOrNotCb">Skip Matching</label>
                                            </div>
                                            <div className="flex-item compare-btn-outer">
                                                <button id="process-compare" className="compare-btn" onClick={() => submitCompareRB()}>Compare</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <CompareTab4 activeTab={activeTab} handleCompareDownloadWrap={handleCompareDownloadWrap} />
                </div>
            </div>
        </div>
    )
}

export default CompareTabPopup;