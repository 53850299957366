import apiService from "../Services/ApiService";
import AuthProviderService from "../Services/AuthProviderService";
import Utils from "./Utils";

class ProcessEDBWrap {
    async prepareReportQueue(reportIds, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/prepare_report_queue";        
        console.log(url)         
        // const queryParams = {
        //     report_id:reportIds.join(",")
        //     ,'manual_mode':'yes'            
        // };
        const authClaim = await AuthProviderService.getClaims();   
        let personaAccess = sessionStorage.getItem("roleaccess");
        const queryParams = {
            report_id:JSON.stringify(reportIds)
            ,'manual_mode':'yes'    
            ,'persona':personaAccess
            ,'email': authClaim.username
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async triggerEDB(reportIds, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/edb_trigger";        
        console.log(url)         
        // const queryParams = {
        //     report_id:reportIds.join(",")
        //     ,'manual_mode':'yes'            
        // };
        const queryParams = {
            report_id:JSON.stringify(reportIds)
            ,'manual_mode':'yes'            
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async checkEDB(data, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/check_trigger_edb";        
        console.log(url)         
        const queryParams = {
            report_id:JSON.stringify(data.report_id),
            job_run_id:data.jobRunId
            ,'manual_mode':'yes'            
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async transfer_bills(data, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/transfer_bills";        
        console.log(url)         
        const queryParams = {
            report_id:JSON.stringify(data.report_id)
            ,'manual_mode':'yes'            
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    // async split_pdf(data, onComplete, onError) {
    //     const env = Utils.getEnvVars();
    //     let endpoint = env.API_URL;
    //     let url = endpoint + "/split_pdf_by_content";        
    //     console.log(url)         
    //     const queryParams = {
    //         report_id:data.report_id
    //         ,'manual_mode':'yes'            
    //     };
    //     const queryString = Object.keys(queryParams)
    //         .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    //         .join('&');
    //     if (queryString) url = `${url}?${queryString}`
    //     await apiService.fetchGetEDBData(url, onComplete, onError);      
    // }

    fetchCompareData (files, onComplete, onError) {
        var count = files.length;
        console.log(count)
        var startFile = 0;
        this.fetchCompareDataWrap(files, startFile, count, onComplete, onError)
    }    

    async fetchCompareDataWrap(files, currentVal, count, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/compare_edb_report";        
        console.log(url) 
        const file = files[currentVal];
        let getUniqueTimeStampStr = file.uploadTime;
        console.log(file)
        let that = this;                
        const queryParams = {
            filename: file.name
            , uniqueTimestampStr: getUniqueTimeStampStr
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetData(url, file.name, getUniqueTimeStampStr, files, currentVal, count, onComplete, onError, 
            function (files, currentVal, count, onComplete, onError, data) {
                currentVal++;
                if (currentVal < count) {
                    that.fetchCompareDataWrap(files, currentVal, count, onComplete, onError)
                } else {
                    if (onComplete) onComplete(data); //,onError
                }
        });      
    }  

    handleCompareDownload (files, onComplete, onError) {
        var count = files.length;
        console.log(count)
        var startFile = 0;
        this.handleCompareDownloadWrap(files, startFile, count, onComplete, onError)
    }

    handleCompareDownloadWrap = async (files, currentVal, count, onComplete, onError) => {
        const file = files[currentVal];
        let fName = file.name.replace(/pdf/i, 'csv');
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/download_report";
        let uniqueTimestampStr = file.uploadTime;
        console.log(url)
        const queryParams = {
            filename: fName
            , mode: 'downloadxl'
            , uniqueTimestampStr: uniqueTimestampStr
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        let that = this;
        await apiService.fetchDownloadDataXLWDB(url, fName, files, currentVal, count, onComplete, onError, 
            function (files, currentVal, count, onComplete, onError) {
                currentVal++;
                if (currentVal < count) {
                    that.handleCompareDownloadWrap(files, currentVal, count, onComplete, onError)
                } else {
                    if (onComplete) onComplete(); //,onError
                }
        });
    }

    async fetchCronLogs(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/fetch_process_logs";        
        console.log(url)      
        const authClaim = await AuthProviderService.getClaims();   
        let personaAccess = sessionStorage.getItem("roleaccess");
        const queryParams = {
            'persona':personaAccess
            ,'email': authClaim.username
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async fetchCompletedReportIds(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let personaAccess = sessionStorage.getItem("roleaccess");
        let url = endpoint + "/check_completed_reports";        
        console.log(url)         
        const queryParams = {
            'persona':personaAccess
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }

    async fetchCompletedReportIdsNightly(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let personaAccess = sessionStorage.getItem("roleaccess");
        let url = endpoint + "/check_completed_nightly_reports";        
        console.log(url)         
        const queryParams = {
            'persona':personaAccess
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }

    async fetchPersona(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/auth/me";        
        console.log(url)   
        // const authClaim = await AuthProviderService.getClaims();   
        // const queryParams = {
        //     'email': authClaim.username
        // };
        // const queryString = Object.keys(queryParams)
        //     .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        //     .join('&');
        // if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBAuthData(url, onComplete, onError);      
    }  
}

const ProcessEDB = new ProcessEDBWrap();

export default ProcessEDB;