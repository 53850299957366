import { useEffect } from "react";
import Utils from "../Common/Utils";

const FileSubmitEDB = ({ edbFiles, setEDBFiles,setReportIds,reportIds }) => {
    const filesSelectWrap = (selectedFiles) => {
        const newFiles = Array.from(selectedFiles);
        for (let inFile in newFiles) {
            if (newFiles[inFile].name) {
                if (Utils.hasSpecialCharacters(newFiles[inFile].name)) {
                    alert("Please enter files with no special characters");
                    return false;
                }
            }
        }

        let getUniqueTimeStampStr = Utils.getUniqueTimeStamp();
        getUniqueTimeStampStr = "0"
        newFiles.map((inFile) => {
            return inFile['uploadTime'] = getUniqueTimeStampStr
        })
        setEDBFiles([...newFiles])
        setReportIds([])
        document.getElementById('singlereportidVal').value = ""
    }

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        document.getElementById('singlereportidVal').value = '';
        console.log("handleFileChange",selectedFiles)
        if (selectedFiles && selectedFiles.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                console.log(event.target.result)
                const csvString = event.target.result;
                const rows = csvString.split('\n');
                const data = rows.map(row => row.split(',').map(item => item.trim()).filter(item => item !== ''));
                console.log(data);
                let finalData = []
                let getUniqueTimeStampStr = Utils.getUniqueTimeStamp();
                getUniqueTimeStampStr = "0"
                data.filter((di)=> {
                    if(di[0] && di[0] !== "") finalData.push({reportid:di[0],uploadTime:getUniqueTimeStampStr})
                })
                console.log(finalData);
                setReportIds([...finalData])
            };
            reader.readAsText(file);
            if (!filesSelectWrap(selectedFiles)) return;
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        document.getElementById('singlereportidVal').value = '';
        const droppedFiles = event.dataTransfer.files;
        console.log("handleDrop",droppedFiles)
        if (droppedFiles.length > 0) {
            if (!filesSelectWrap(droppedFiles)) return;
        }
    };

    const handleFileDelete = (event) => {
        console.log("handleFileDelete")
        filesSelectWrap([]);
        document.getElementsByClassName("reportFile")[0].value = "";
    };

    const setSingleReportId = (event) => {
        console.log("setSingleReportId",event)
        let getUniqueTimeStampStr = Utils.getUniqueTimeStamp();
        getUniqueTimeStampStr = "0"
        setReportIds([{reportid:event.target.value,uploadTime:getUniqueTimeStampStr}])
        setEDBFiles([])
        document.getElementById('reportEDBFile').value = ""
    }

    const downloadCSV = (data) => {
        const csvContent = data.join("\n"); // Join data with newlines for CSV format
      
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
      
        const hiddenLink = document.createElement('a');
        hiddenLink.href = url;
        hiddenLink.download = 'reportIds.csv'; // Set desired filename
      
        document.body.appendChild(hiddenLink);
        hiddenLink.click();
      
        document.body.removeChild(hiddenLink);
        window.URL.revokeObjectURL(url);
    };

    const handleDownloadRTmpl = (event) => {
        const data = [
            "9EF26823XYZ382AF",
            "9EF26823XDGFZ382AF",
            "9EF26823XABCZ382AF",
        ];
        downloadCSV(data);
    }

    useEffect(()=> {
        filesSelectWrap([]);
        document.getElementsByClassName("reportFile")[0].value = "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
        <div className="box-container-EDB">
            <div>
                <input type="text" id="singlereportidVal" className="file-drop-input" placeholder="Single Report id" onChange={(event)=>setSingleReportId(event)}/>
            </div>
            <div className="margintop10">
                <label>Or</label>
            </div>
            <div>
                <label className="download-edb-tmpl" onClick={(event) => handleDownloadRTmpl(event)}>Download Mutliple report csv tmpl</label>
            </div>
            <div className="file-drop-area margintop10" onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}>
                <div>
                    <span className="fake-btn">Choose files</span>
                    <span className="file-msg">
                        {(edbFiles && edbFiles.length === 1) && edbFiles[0].name}
                        {(edbFiles && edbFiles.length === 0) && "or drop files here"}
                        {(edbFiles && edbFiles.length > 1) && edbFiles.length + " files selected"}
                    </span>
                    <input name="file" className="file-input reportFile" type="file" accept=".csv" id="reportEDBFile" onChange={handleFileChange} />
                    <div className="item-delete" style={(edbFiles && edbFiles.length >= 1) ? { display: "block" } : { display: "none" }} onClick={handleFileDelete}></div>
                </div>
            </div>
            <div className="margintop10"></div>
            <button id="report-gen-submit" className="file-submit-btn">Submit</button>
        </div>
    )
}

export default FileSubmitEDB;