import { useEffect, useState } from 'react';
import bg from '../img/Discovery_TeamsBackground_Molecule21.png';
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import Utils from '../Common/Utils';
import apiService from '../Services/ApiService';
import AccessDenied from './AccessDenied';
import AuthCallback from './AuthCallback';

const BodyWrap = ({ initLoad, notFound, accessDenied, isloading, setValidUser, setUserCheck, isValidUser,callback, setinitLoading, initloading,validPersona,setValidPersona, setpersonaAccess, personaAccess, setuserName, userName }) => {
    const [drapeSheer, setDrapeSheer] = useState(false);
    const [toastMsg, setToastMsg] = useState(false);
    const [toastType, setToastType] = useState(false);
    const [toastDur, setToastDur] = useState(3000);
    const [hideDom, setHideDom] = useState(false);
    const [showComparePopup, setShowComparePopup] = useState(false);
    const [showCompareFile, setShowCompareFile] = useState({})
    const [files, setFiles] = useState([]);
    const [edbFiles, setEDBFiles] = useState([]);
    const [cookieFile, setCookieFile] = useState([]);
    const [validateCheckBox, setValidateCheckBox] = useState("validateReportId");
    const [reportIds,setReportIds] = useState([])
    const showToast = (msg, tt, td) => {
        setToastMsg(msg);
        setToastType(tt);
        setToastDur(td);
        setHideDom(false)
    };
    const handleIncrement = (inFile) => {
        setFiles(() => [...inFile]);
    };
    const handleEDBIncrement = (inFile) => {
        setEDBFiles(() => [...inFile]);
    };
    useEffect(() => {
        let cookieValue = Utils.checkAndGetCookie();
        console.log(cookieValue)
        setCookieFile(cookieValue['dropFileName'])
    }, []);

    const handleCompareDownload = async (filename,uniqueTimestampStr) => {
        setDrapeSheer(true)
        let fName = filename.replace(/pdf/i, 'csv');
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/download_report";
        console.log(url)
        const queryParams = {
            filename: fName
            , mode: 'downloadxl'
            , uniqueTimestampStr: uniqueTimestampStr
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchDownloadDataXL(url, fName, function (data) {
            console.log("ajax complete ", data)
            setDrapeSheer(false)
        }, function (error) {
            setDrapeSheer(false)
            showToast("Error download compare!!!", "danger", 5000);
        });
    }
    return (
        <div style={{ backgroundImage: `url(${bg})` }} className='top-container'>
            <Header initLoad={initLoad} notFound={notFound} accessDenied={accessDenied} isloading={isloading} files={files} cookieFile={cookieFile} setCookieFile={setCookieFile} setDrapeSheer={setDrapeSheer} showToast={showToast} setShowComparePopup={setShowComparePopup} setShowCompareFile={setShowCompareFile} handleCompareDownload={handleCompareDownload} setuserName={setuserName} userName={userName}/>
            {accessDenied ? 
                <AccessDenied /> : 
                isloading ? 
                    <AuthCallback setValidUser={setValidUser} setUserCheck={setUserCheck}  isValidUser={isValidUser} callback={callback} setinitLoading={setinitLoading} initloading={initloading}  setValidPersona={setValidPersona} validPersona={validPersona}  setpersonaAccess={setpersonaAccess} personaAccess={personaAccess} setuserName={setuserName} userName={userName}/> :
                    <Content initLoad={initLoad} notFound={notFound} files={files} handleIncrement={handleIncrement} handleEDBIncrement={handleEDBIncrement} cookieFile={cookieFile} setCookieFile={setCookieFile} setDrapeSheer={setDrapeSheer} drapeSheer={drapeSheer} showToast={showToast} toastMsg={toastMsg} toastType={toastType} toastDur={toastDur} hideDom={hideDom} setHideDom={setHideDom} showComparePopup={showComparePopup} setShowComparePopup={setShowComparePopup} showCompareFile={showCompareFile} setShowCompareFile={setShowCompareFile} handleCompareDownload={handleCompareDownload} setValidateCheckBox={setValidateCheckBox} validateCheckBox={validateCheckBox} setReportIds={setReportIds} reportIds={reportIds} setEDBFiles={setEDBFiles} edbFiles={edbFiles} initloading={initloading} setValidPersona={setValidPersona} validPersona={validPersona} setpersonaAccess={setpersonaAccess} personaAccess={personaAccess}/> 
            }
            <Footer initLoad={initLoad} notFound={notFound} />
        </div>
    )
}

export default BodyWrap;