import apiService from "../Services/ApiService";
import Utils from "./Utils";

class ProcessPDFWrap {
    ReadTextFromPDF(files, processType, onComplete, onError) {               
        var count = files.length;
        console.log(count)
        var startFile = 0;
        if(processType === "report") {
            this.ReadTableTextFromPDFWrap(files, startFile, count, onComplete, onError)
        } else if(processType === "bill") {
            this.ReadBillTextFromPDFWrap(files, startFile, count, onComplete, onError)
        }
    }

    async ReadTableTextFromPDFWrap(files, currentVal, count, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/process_report";        
        console.log(url) 
        const file = files[currentVal];
        let getUniqueTimeStampStr = file.uploadTime;
        console.log(file)
        let that = this;                
        const queryParams = {
            filename: file.name
            , uniqueTimestampStr: getUniqueTimeStampStr
            ,processPdfType:'report'
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetData(url, file.name, getUniqueTimeStampStr, files, currentVal, count, onComplete, onError, 
            function (files, currentVal, count, onComplete, onError, data) {
                console.log("check next",currentVal,count)
                currentVal++;
                if (currentVal < count) {
                    that.ReadTableTextFromPDFWrap(files, currentVal, count, onComplete, onError)
                } else {
                    if (onComplete) onComplete(data); //,onError
                }
        });      
    }  

    async ReadBillTextFromPDFWrap(files, currentVal, count, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/process_bill";        
        console.log(url) 
        const file = files[currentVal];
        let getUniqueTimeStampStr = file.uploadTime;
        console.log(file)
        let that = this;                
        const queryParams = {
            filename: file.name
            , uniqueTimestampStr: getUniqueTimeStampStr
            ,processPdfType:'bill'
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetData(url, file.name, getUniqueTimeStampStr, files, currentVal, count, onComplete, onError, 
            function (files, currentVal, count, onComplete, onError, data) {
                console.log("check next",currentVal,count)
                currentVal++;
                if (currentVal < count) {
                    that.ReadBillTextFromPDFWrap(files, currentVal, count, onComplete, onError)
                } else {
                    if (onComplete) onComplete(data); //,onError
                }
        });      
    }  
}

const ProcessPDF = new ProcessPDFWrap();

export default ProcessPDF;