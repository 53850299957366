import { useEffect } from "react";
import Utils from "../Common/Utils";
import "../Styles/DragDropBrowse.css"

const DragDropBrowse = ({files,onSetFiles}) => {
    const filesSelectWrap = (selectedFiles) => {
        const newFiles = Array.from(selectedFiles);
        for (let inFile in newFiles) {
            if (newFiles[inFile].name) {
                if (Utils.hasSpecialCharacters(newFiles[inFile].name)) {
                    alert("Please enter files with no special characters");
                    return false;
                }
            }
        }

        let getUniqueTimeStampStr = Utils.getUniqueTimeStamp();
        newFiles.map((inFile) => {
            return inFile['uploadTime'] = getUniqueTimeStampStr
        })
        onSetFiles([...newFiles])
    }

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            if (!filesSelectWrap(selectedFiles)) return;
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
            if (!filesSelectWrap(droppedFiles)) return;
        }
    };

    const handleFileDelete = (event) => {
        filesSelectWrap([]);
        document.getElementsByClassName("reportFile")[0].value = "";
    };

    useEffect(()=> {
        filesSelectWrap([]);
        document.getElementsByClassName("reportFile")[0].value = "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="file-drop-area" onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}>
            <div>
                <span className="fake-btn">Choose files</span>
                <span className="file-msg">
                    {(files && files.length === 1) && files[0].name}
                    {(files && files.length === 0) && "or drop files here"}
                    {(files && files.length > 1) && files.length + " files selected"}
                </span>
                <input name="file" className="file-input reportFile" type="file" accept=".pdf,.csv,.xlsx,.xls" id="reportFile" onChange={handleFileChange} />
                <div className="item-delete" style={(files && files.length >= 1) ? { display: "block" } : { display: "none" }} onClick={handleFileDelete}></div>
            </div>
        </div>
    )
}

export default DragDropBrowse;