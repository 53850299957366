import apiService from "../Services/ApiService";
import AuthProviderService from "../Services/AuthProviderService";
import Utils from "./Utils";

class ChunkUploadWrap {
    uploadChunkFile(files, onComplete, onError) {        
        var count = files.length;
        console.log(count)
        var startFile = 0;
        this.uploadChunkFileWrap(files, startFile, count, onComplete, onError)
    }

    async prepareReportManual(filenames, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/prepare_manual_report_queue";        
        console.log(url)         
        // const queryParams = {
        //     report_id:reportIds.join(",")
        //     ,'manual_mode':'yes'            
        // };
        const authClaim = await AuthProviderService.getClaims();   
        let personaAccess = sessionStorage.getItem("roleaccess");
        const queryParams = {
            filenames:JSON.stringify(filenames)
            ,'manual_mode':'yes'    
            ,'persona':personaAccess
            ,'email': authClaim.username
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async prepareReportManualComplete(filenames, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/complete_manual_report_queue";        
        console.log(url)         
        // const queryParams = {
        //     report_id:reportIds.join(",")
        //     ,'manual_mode':'yes'            
        // };
        const authClaim = await AuthProviderService.getClaims();   
        let personaAccess = sessionStorage.getItem("roleaccess");
        const queryParams = {
            filenames:JSON.stringify(filenames)
            ,'manual_mode':'yes'    
            ,'persona':personaAccess
            ,'email': authClaim.username
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }  

    async fetchCompletedReportIdsManual(onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        const authClaim = await AuthProviderService.getClaims();   
        let personaAccess = sessionStorage.getItem("roleaccess");
        let url = endpoint + "/check_manual_completed_reports";        
        console.log(url)         
        const queryParams = {
            'persona':personaAccess
            ,'email': authClaim.username
        };
        const queryString = Object.keys(queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');
        if (queryString) url = `${url}?${queryString}`
        await apiService.fetchGetEDBData(url, onComplete, onError);      
    }

    async uploadChunkFileWrap(files, currentVal, count, onComplete, onError) {
        const env = Utils.getEnvVars();
        let endpoint = env.API_URL;
        let url = endpoint + "/upload_chunk_raw";        
        console.log(url)
        const file = files[currentVal];
        let getUniqueTimeStampStr = file.uploadTime;
        console.log(file)
        const chunkSize = 3 * 1024 * 1024; // 5MB chunk size

        let start = 0;
        let end = Math.min(chunkSize, file.size);
        let partNumber = 1;

        while (start < file.size) {
            const chunk = file.slice(start, end);

            // Send the chunk to Lambda
            let chunkRes = await apiService.sendChunkToLambda(url, chunk, file.name, partNumber, getUniqueTimeStampStr);
            if (chunkRes.error) {
                if (onError) onError(chunkRes.error);
                return false;
            }
            // upload_id = res.upload_id || ''
            start = end;
            end = Math.min(start + chunkSize, file.size);
            partNumber++;
        }        

        // await completeUpload(url, file.name, upload_id, undefined,onError);
        currentVal++;
        if (currentVal < count) {
            this.uploadChunkFileWrap(files, currentVal, count, onComplete, onError)
        } else {
            if (onComplete) onComplete(); //,onError
        }
    }
}

const ChunkUpload = new ChunkUploadWrap();
export default ChunkUpload;