const CompareTab2 = ({ activeTab, previewError, showLoading, csvHeader, csvBody, showDownload, downloadCsv, downloadbill }) => {
    return (
        activeTab === 2 &&
        <div id="Tab2" className="tabcontent">
            <div id="previewOCRTab">
                {!previewError ? (showLoading ?
                    <div id="previewOCRLoader" className="loading-circle_inner"></div>
                    :
                    <div id="csvPreviewModal_ocr">
                        <div>
                            <div>
                                <div id="csvPreview_ocr" className="csv-preview">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {
                                                    csvHeader.map((col, idx) => {
                                                        return <th key={idx}>{col}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        {
                                            csvBody.map((col, idx) => {
                                                return (<tr>
                                                    {
                                                        col.map((colinner, idxinner) => {
                                                            return colinner.match(/.pdf/) ?
                                                                <td><label className="download-bill-label" onClick={downloadbill}>{colinner}</label></td> :
                                                                <td key={idxinner}>{colinner}</td>
                                                        })
                                                    }
                                                </tr>)
                                            })
                                        }
                                    </table>
                                </div>
                                <div>
                                    {/* <button type="button" className="btn btn-secondary" id="prevPageBtn_ocr" disabled>&lt; Prev</button>
                                    <button type="button" className="btn btn-secondary" id="nextPageBtn_ocr">Next &gt;</button> */}
                                    {!showDownload ? <></> : <button type="button" className="btn btn-secondary downloadBtn_ocr" onClick={(e) => downloadCsv(e, "bill", "/download_ocr_csv")}>Download</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <div id="Error_OCR"><h2>Error!!!</h2></div>}
            </div>
        </div>

    )
}

export default CompareTab2;